body{
  background-color: #0f0f0f;
  color:white;
  height: 100vh;
  font-family: "FuturaBold";
  letter-spacing: 1px;

}
body a{
  color: white;
  text-decoration: none;
}
.app{
  position: absolute;
}

.topMenu nav{
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 80px;
  text-transform:uppercase;
  font-size: 16px;
  font-family: "FuturaBoldItalic"
}
.topMenu nav div a{
  padding: 10px;
}
.topMenu nav .contact{
  border: 1px solid white;
  padding: 10px 30px;
}
.topMenu img{
  max-width: 300px;
  display: inline-block;
}

.homePolygon{
  clip-path: polygon(59% 0, 100% 0%, 100% 100%, 17% 100%);
  width: 60vw;
  margin-left: 40vw;
  height: 100vh;
  background: transparent linear-gradient(143deg, #214B7C 0%, #08213E 100%) 0% 0% no-repeat padding-box;
  position: relative;
}
.iconContainer {
  color: #101010;
  display: flex;
  justify-content: center;
  }
.iconContainer div{
  display: flex;
  align-items: center;
  padding: 50px;
}
.iconContainer img{
  width: 60px;
}
.iconContainer p{
  font-size: 14px;
  max-width: 140px;
  padding: 0 30px 0 10px;
  line-height: 22px;
}

.articleSmallContainer{
  background-color: #101010;
  padding: 150px 0;
}
.articleSmallContainer h1, .articleSmallContainer h2{
  font-size: 50px;
  display:block;
  text-align: center;
  line-height: 60px;
}
.articleSmallContainer a{
  background-color: #1D82D6;
  font-family: "FuturaBoldItalic";
  font-size: 18px;
  padding: 10px 25px;
  margin: 10px 0;
  text-transform: uppercase;
  margin-left: 50%;
  transform: translateX(-50%);
  display: inline-block;
}
.over{
  text-align: center;
}
.over p{
  color: #1D82D6;
  font-size: 25px;
  text-transform: uppercase;
  font-family: "FuturaBoldItalic";
  margin-top: 30vh;
  margin-bottom: 30px;
}

.over h1{
  font-size: 80px;
  text-transform: uppercase;
}
.footerContainer{
  padding: 50px;
}
.footerContainer .hr{
  min-height: 100px;

}
.footerContainer .hr img{
  width: 60px;
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%, -50%);
  background-color: #101010;
  padding: 20px;
}

.footerContainer .hr div{
  width: 80vw;
  height: 1px;
  background-color: grey;
  margin: 0 auto;
  position: relative;
}

.footerContainer footer{
  display: flex;
  justify-content:space-around;
  width: 80vw;
  margin: 0 auto 40px auto;

}
.footerContainer footer h2, .footerContainer footer nav{
  text-transform: uppercase;
  font-style: oblique;
  font-family: "FuturaBoldItalic";
}
.footerContainer footer p, .footerContainer footer .footerGegevens a{
  font-family: "FuturaLight";
  font-style: italic;
}
.footerContainer footer nav{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.footerContainer footer div:first-of-type{
  width: 30vw;
}
.footerContainer footer .footerLogo img{
  max-width: 95%;
}
.footerContainer footer .footerLogo div{
  margin-left: 35px;
}

.footerContainer footer .footerLogo div svg{
  font-size: 25px;
  padding: 20px;
}
.footerContainer footer .footerGegevens p, .footerContainer footer .footerGegevens a{
  padding-top: 45px;
  display: block;
}
.footerContainer footer .footerBy img{
  width: 80px;
  padding: 20px;
}
.contactContainer{
  text-align:center;
  padding: 100px 0 250px 0;
}
.projectDetailIntro{
  padding: 100px 0 0px 0;
}
.contactContainer h1, .contactContainer a{
  color: #1D82D6;
}
.contactContainer h1{
  font-size: 22px;
  margin-bottom: 30px;
}
.contactContainer p{
  font-size: 24px;
}
.contactContainer p:first-of-type{
  font-size: 60px;
  font-family: "FuturaBoldItalic";
  width: 35vw;
  text-align:center;
  margin: 0 auto;
  padding-bottom: 20px;
}
.contactContainer a{
  text-decoration: underline;
}
.contactContainer h1, .contactContainer p:first-of-type{
  text-transform: uppercase;
  font-family: "FuturaBoldItalic";
}

.articleContainer{
  display: flex;
  flex-direction: row;
  width: 90vw;
  margin: 150px auto 0 auto;
  align-items: center;
  justify-content:center;

}
.articleContainer article{
  width: 50%;
  margin-left: 15vw; 

}

.articleContainer article h1{
  font-size: 55px;
  font-family: "FuturaBold";
  max-width: 500px;
  margin-bottom: 20px;
}
.articleContainer article h1 b{
  color: #1D82D6;

}

.articleContainer article p{
  color: white;
  font-size: 16px;
  font-style: normal;
  font-family: "FuturaLight";
  max-width: 60%;
  line-height: 40px;

}
.articleContainer article a{
  background-color: black;
  padding: 10px 30px;
  border-radius: 5%;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-style: italic;
  border: 1px solid white;
  margin-top: 20px;
  display: inline-block;
}

.articleImages{
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-right: 8vw;
}
.articleImages div{
  background-size: cover;
  border: 1px solid lightgrey;
  width: 100%;
  height:500px;
  margin-right: 10px;
}
.articleImages div:nth-child(2) { 
  transform: translateY(-4rem); 
  margin-right: 0;
}
.overContainer .articleImages div{
  height: 350px;
}
.overContainer .articleImages div:first-of-type{
  background-position: -180px;
}
.overContainer .articleImages div{
  background-position: -120px;
}
.articleHome {
  margin-bottom: 100px;
}

.articleHome article p{
  line-height: 28px;
}
.articleHome article a{
  background-color: #1D82D6;
  border: none;
  border-radius: 0!important;
  font-style: normal;
  font-family: "FuturaBoldItalic";
  letter-spacing: 1px;
}
.articleHome .homeRecensie span{
  display: block;
  margin-top: 100px;
  margin-bottom: 10px;
}
.articleHome .homeRecensie svg{
  color: #fcac29;
}
.articleHome .homeRecensie q{
  font-family: "FuturaLight";
  width: 80%;
  display: block;
}
.articleHome .homeRecensie p{
  font-family: "FuturaBold";
}
.articleHome .articleImages div{
  background-size: cover;
}
.articleMoois article{
  background-size: 220px;
  background-repeat: no-repeat;

}
.articleMoois article p{
  font-family: "FuturaBold";
  font-size: 40px;
}
.articleMoois article{
  padding-left: 100px;
}
.articleMoois .profileBossMan{
  display: flex;
  margin-top: 50px;
  align-items: center;
  
}
.articleMoois .profilePicture img{
  width: 80px;
  border-radius: 100%;
  margin-right: 30px;
}
.articleMoois span p{
  font-family: "FuturaLight";
  font-size: 16px;
  line-height: 20px;
  width: 500px;
}
.articleMoois span p:first-of-type{
  font-style: italic;
}
.articleMoois span p:last-of-type{
  font-family: "FuturaBold";
}

.articleMoois .articleNav{
  width: 40vw;
  margin-right: 100px;
  display: flex;
  flex-direction: column;

}
.articleMoois .articleNav li{
  list-style: none;
  padding: 20px 10px 20px 0;
  border-bottom: 1px solid lightgrey;
}
.articleMoois .articleNav .navItems, .articleMoois .articleNav p {
  padding-top: 20px;
  padding-left: 50px;
    font-family: "FuturaLight";
}
.articleMoois .articleNav .navItems li{
  padding: 0;
  border-bottom: 0px solid lightgrey;
}
.articleMoois .articleNav li:hover{
  color:  #1D82D6;
}
.projectContainer{
  width: 70vw;
  display: block;
  margin: 0 auto;
}
.projectContainer li{
  list-style: none;
  position: relative;
  margin-bottom: 100px;
}
.projectContainer li a img{
  width: 100%;
  display: block;
  border: 1px solid white;
}
.projectContainer .projectCopy{
  position: absolute;
  bottom: 30px;
  right: 30px;
  text-align:end;
}
.projectContainer .projectCopy p:first-of-type{
  font-size: 33px;
}
.projectDetailImageContainer {
  width: 70vw;
  margin: 0 auto;
}
.projectDetailImageContainer img{
  width: 100%;
  margin-bottom: 100px;
  border: 1px solid white;
}
.projectDetailImageContainer img:last-of-type{
  margin-bottom: 0px;
}
.recensieContainer{
  max-width: 70vw;
  margin: 0 auto;
}
.recensieContainer div{
  display: flex;
  flex-direction: row;
}
.recensieContainer h1, .homeProjecten h1{
  font-family: "FuturaBoldItalic";
  font-size: 35px;
  margin-top: 100px;
  text-transform: uppercase;
}
.homeProjecten h1{
  display: block;
  text-align: center;
  margin-bottom: 50px;
}
.homeProjecten .homeProjectenButton{
  padding: 10px 30px;
  border: 1px solid white;
  display: inline-block;
  margin-left: 50%;
  transform: translateX(-50%);
  display: inline-block;
}
.recensieContainer .homeRecensie span{
  margin-top: 30px;
}



@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.toggleIcon{
  display: none;
}
.topMenu .mobile{
  display: none;
}
.topMenu .desktop{
  display: flex;
}
@media screen and (max-width: 1024px){
.toggleIcon{
  display: block;
  font-size: 25px;
}
.menuNav{
  flex-direction: column;
  display: flex;
}
.topMenu nav{
  padding: 0;
}
.topMenu .desktop{
  display: none;
}
.topMenu .mobile{
  display: flex;
}
.topMenu .menuItemsMobile{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 80vh;
  align-items: center;
  justify-content:center;
 
}
.topMenu .menuItemsMobile a{
  padding: 30px;
}
.topMenu img{
  width: 200px;
}
.articleContainer article h1{
  font-size: 40px;
}
.footerContainer footer{
  flex-direction: column;
  margin: 0;
  align-items: center;
}
.footerContainer footer .footerLogo img {
    display: none;
}
.footerContainer footer .footerLogo div {
  margin: 0;
  text-align: center;
}
.footerContainer .footerGegevens{
  margin: 30px 0 50px 0;
  text-align: center;
}
.footerContainer .footerMenu{
  display: none;
}
.articleSmallContainer h1, .articleSmallContainer h2{
  font-size: 35px;
  max-width: 95vw;
}
.contactContainer p:first-of-type{
  width: 95vw;
  padding: 10px;
}
.contactContainer p{
  padding: 10px;
}
.iconContainer{
  flex-direction: column;
  align-items:center;
}
.articleContainer{
  flex-direction: column;
}
.articleContainer article{
  width: 100%;
  margin-left: 0;
  overflow: hidden;
}
.articleContainer article p{
  max-width: 90vw;

}
.articleImages{
  margin-right: 0;
  padding-top: 100px;
  width: 100%;
}
.recensieContainer div{
  flex-direction: column;
}
.articleHome{
  margin-top: 0px;
  
}
.articleHome article{
  text-align: center;
}
.articleHome .homeRecensie{
  display: none;
}
.articleMoois{
  max-width: 100vw;
}
.articleMoois .articleBossMan{
  flex-direction: column;
  display: flex;
  overflow: hidden;
  max-width: 300px;
}
}

.homeRoute{
  position: absolute;
  left: 0;
  top: 0;
}
.app{
  position: relative;
}